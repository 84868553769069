<template>
  <div class="loading-container rounded-lg">
    <div class="planet-system">
      <img alt="Logo" class="logo" :src="imageSrc" />
      <div class="spinner spinner-1"></div>
      <div class="spinner spinner-2"></div>
      <div class="spinner spinner-3"></div>
    </div>
    <div class="loading-text">
      Loading {{ title }}
      <span class="dot dot1">.</span>
      <span class="dot dot2">.</span>
      <span class="dot dot3">.</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  title?: string
  tableName?: string
}>()
const assetBrandFolder = useState<string>("assetBrandFolder")
const imageSrc = computed(() => `/brand/${assetBrandFolder.value}/logo-small-white.png`)
</script>

<style scoped>
.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}

.planet-system {
  position: relative;
  width: 120px;
  height: 120px;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 38px;
}

.spinner {
  position: absolute;
  border: 4px solid #e3e3e370;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 1.5s linear infinite;
}

.spinner-1 {
  border-top: 4px solid #15bbfd;
  animation-duration: 2s;
}

.spinner-2 {
  border-top: 4px solid #0589fd;
  width: 75%;
  height: 75%;
  top: 12.5%;
  left: 12.5%;
  animation-duration: 1.5s;
  animation-direction: reverse;
}

.spinner-3 {
  border-top: 4px solid #3ff3f8;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
  animation-duration: 1s;
}

.loading-text {
  margin-top: 10px;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  display: inline-block;
  opacity: 0;
  animation: fadeinout 1.5s infinite;
}

.dot1 {
  animation-delay: 0.2s;
}

.dot2 {
  animation-delay: 0.4s;
}

.dot3 {
  animation-delay: 0.6s;
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
